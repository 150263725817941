<ng-container *ngIf="!loading; else templateLoading">

  <app-topbar moduleSlug="profilplus-site">
    <ng-container title>
      <nav aria-label="breadcrumb" role="navigation">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a [routerLink]="['/']">Tableau de bord</a></li>
          <li class="breadcrumb-item">
            <a [routerLink]="['/' + currentModule.slug]">
              {{currentModule.name}}
            </a>
          </li>
          <li class="breadcrumb-item"><a [routerLink]="['/' + currentModule.slug, 'pages']">Pages CMS</a></li>
          <ng-container  *ngIf="(editMode.active && editMode.data); else templateElse">
            <li class="breadcrumb-item active"><a href="javascript:;">{{editMode.data.title}}</a></li>
          </ng-container>
          <ng-template #templateElse>
            <li class="breadcrumb-item active"><a href="javascript:;">Créer une page CMS</a></li>
          </ng-template>
        </ol>
      </nav>
    </ng-container>
  </app-topbar>

  <ng-container *ngIf="editFormGroup">
    <div class="ppp-agency-edition row" *ngIf="!editMode.active ||(editMode.active && editMode.data)">
      <div class="col-lg-12">
        <form class="form" [formGroup]="editFormGroup" (ngSubmit)="onSubmit()">
          <div class="card">
            <div class="card-header card-header-rose card-header-icon">
              <div class="card-icon">
                <i class="material-icons">domain</i>
              </div>
              <ng-container *ngIf="(editMode.active && editMode.data); else templateElse">
                <h4 class="card-title">Édition de la page CMS : <strong class="description">{{editMode.data.title}}</strong></h4>
              </ng-container>
              <ng-template #templateElse>
                <h4 class="card-title">Création d'une nouvelle page CMS</h4>
              </ng-template>
            </div>

            <div class="card-body">
              <div class="col-lg-6 col-md-12">
                <div class="form-group" [ngClass]="{ 'has-danger': submitted && f.parent_menu.errors }">
                  <label>Menu parent</label>
                  <select class="selectpicker-vanilla" formControlName="parent_menu">
                    <option value="" disabled>Choisir</option>
                    <option [value]="parentMenuLabel.id" *ngFor="let parentMenuLabel of parentMenus">{{parentMenuLabel.label}}</option>
                  </select>
                </div>
              </div>

              <ng-container *ngIf="f.parent_menu.value && f.parent_menu.value == 34">
                <div class="col-lg-6 col-md-12">
                  <p class="form-group" [ngClass]="{ 'has-danger': submitted && f.start_at.errors }">
                    <label for="start_at" class="bmd-label-floating">Date de début d'affichage</label>
                    <input type="date" class="form-control" id="start_at" formControlName="start_at">
                  </p>
                </div>
                <div class="col-lg-6 col-md-12">
                  <p class="form-group" [ngClass]="{ 'has-danger': submitted && f.end_at.errors }">
                    <label for="end_at" class="bmd-label-floating">Date de fin d'affichage</label>
                    <input type="date" class="form-control" id="end_at" formControlName="end_at">
                  </p>
                </div>
              </ng-container>

              <div class="col-lg-6 col-md-12" *ngIf="currentModule.slug == 'website'">
                <div class="form-group" [ngClass]="{'has-danger': submitted && editFormGroup.value.service === null}">
                  <app-datalist *ngIf="enumsService.enums.services"
                    [items]="enumsService.enums.services"
                    [preselect]="preselectService"
                    [property]="'name'"
                    label="Service"
                    (selected)="onSelectService($event)"
                  >
                  </app-datalist>
                </div>
              </div>

              <div class="col-lg-6 col-md-12">
                <p class="form-group" [ngClass]="{ 'has-danger': submitted && f.rank.errors }">
                  <label for="rank" class="bmd-label-floating">Ordre</label>
                  <input type="number" min="0" max="99999" NumbersOnly [allowDecimals]="false" [allowSign]="false" class="form-control" id="rank" formControlName="rank">
                </p>
              </div>

              <div class="col-lg-6 col-md-12">
                <p class="form-group is-required" [ngClass]="{ 'has-danger': submitted && f.title.errors }">
                  <label for="title" class="bmd-label-floating">Titre de la page</label>
                  <input type="text" class="form-control" name="title" id="title" formControlName="title">
                </p>
              </div>

              <div class="col-lg-6 col-md-12">
                <p class="form-group" [ngClass]="{ 'has-danger': submitted && f.meta_title.errors }">
                  <label for="meta_title" class="bmd-label-floating">Titre meta</label>
                  <input type="text" class="form-control" id="meta_title" formControlName="meta_title">
                </p>
              </div>

              <div class="col-lg-6 col-md-12">
                <p class="form-group" [ngClass]="{ 'has-danger': submitted && f.meta_description.errors }">
                  <label for="meta_description" class="bmd-label-floating">Description meta</label>
                  <input type="text" class="form-control" id="meta_description" formControlName="meta_description">
                </p>
              </div>

              <div class="col-lg-6 col-md-12">
                <p class="form-group is-required" [ngClass]="{ 'has-danger': submitted && f.slug.errors }">
                  <label for="slug" class="bmd-label-floating">Clé unique "SEO" d'identification</label>
                  <input type="text" class="form-control" id="slug" formControlName="slug">
                </p>
              </div>

              <div class="col-lg-6 col-md-12">
                <p class="form-group is-required" [ngClass]="{ 'has-danger': submitted && f.slug_key.errors }">
                  <label for="slug_key" class="bmd-label-floating">Clé unique "interne" d'identification</label>
                  <input type="text" class="form-control" id="slug_key" formControlName="slug_key">
                </p>
              </div>

              <div class="col-lg-6 col-md-12">
                <p class="form-group" [ngClass]="{ 'has-danger': submitted && f.url.errors }">
                  <label for="url" class="bmd-label-floating">URL</label>
                  <input type="text" class="form-control" id="url" formControlName="url">
                </p>
              </div>

              <div class="col-lg-6 col-md-12">
                <p class="form-group form-check">
                  <label class="form-check-label">
                    <input class="form-check-input" type="checkbox" [name]="" formControlName="published"> Page publiée ? (en ligne)
                    <span class="form-check-sign">
                      <span class="check"></span>
                    </span>
                  </label>
                </p>
              </div>

              <br>

              <div class="col-lg-6 col-md-12" *ngIf="currentModule.slug == 'website'">
                <p class="form-group" [ngClass]="{ 'has-danger': submitted && f.emails_notifications.errors }">
                  <label for="emails_notifications" class="bmd-label-floating">Notifications emails</label>
                  <input type="text" class="form-control" name="emails_notifications" id="emails_notifications" formControlName="emails_notifications">
                  <small>Séparer chaque adresse email par une virgule. Ne pas ajouter d'espaces entre chaque adresse email saisie. Exemple : "y.elbarnoussi@profilplus.fr,a.marceau@profilplus.fr"</small>
                </p>
              </div>

              <div class="col-lg-6 col-md-12" *ngIf="currentModule.slug == 'website'">
                <div class="form-group" [ngClass]="{ 'has-danger': submitted && f.universe.errors }">
                  <label class="bmd-label-floating">Univers</label>
                  <select class="selectpicker-vanilla" formControlName="universe">
                    <option value="" disabled>Choisir</option>
                    <option [value]="universLabel.id" *ngFor="let universLabel of enumsService.enums.universes.slice(0,7)">{{universLabel.label}}</option>
                  </select>
                </div>
              </div>

              <br>

              <div class="col-lg-8 col-md-12">
                <div class="form-group" [ngClass]="{ 'has-danger': submitted && f.content.errors }">
                  <label class="bmd-label-floating">Contenu</label>
                  <div class="froalaEditor-container">
                    <ckeditor formControlName="content" [editor]="Editor" [config]="ckConfig"></ckeditor>
                  </div>
                </div>
              </div>

              <br>

              <div class="col-lg-6 col-md-12">
                <div class="form-group">
                  <label>Photo du menu</label>
                  <app-drag-drop-file-input
                    [limit]="1"
                    [forceTypes]="['jpg', 'jpeg', 'png']"
                    appDragDrop
                    [preview]="true"
                    [list]="editMode.active ? editMode.data.page_nav_photo : null"
                    (fileDeleted)="onFilePageNavPhotoSelected(null)"
                    (filesSelected)="onFilePageNavPhotoSelected($event)"></app-drag-drop-file-input>
                </div>
              </div>

              <div class="col-lg-6 col-md-12">
                <div class="form-group">
                  <label>Habillage global / Habillage partie gauche</label>
                  <app-drag-drop-file-input
                    [limit]="1"
                    [forceTypes]="['jpg', 'jpeg', 'png']"
                    appDragDrop
                    [preview]="true"
                    [list]="editMode.active ? editMode.data.background_image : null"
                    (fileDeleted)="onFileBackgroundImageSelected(null)"
                    (filesSelected)="onFileBackgroundImageSelected($event)"></app-drag-drop-file-input>
                </div>
              </div>

              <div class="col-lg-6 col-md-12">
                <div class="form-group">
                  <label>Habillage partie droite</label>
                  <app-drag-drop-file-input
                    [limit]="1"
                    [forceTypes]="['jpg', 'jpeg', 'png']"
                    appDragDrop
                    [preview]="true"
                    [list]="editMode.active ? editMode.data.background_image_right : null"
                    (fileDeleted)="onFileBackgroundImageRightSelected(null)"
                    (filesSelected)="onFileBackgroundImageRightSelected($event)"></app-drag-drop-file-input>
                </div>
              </div>

              <ng-container *ngIf="editMode.active && editMode.data && editMode.data.form_data_file">
                <div class="col-lg-6 col-md-12">
                  <label>Réponses au formulaire : Données</label>
                  <app-drag-drop-file-input
                    [limit]="0"
                    [forceTypes]="null"
                    [removeDeleteAction]="true"
                    [list]="[{name: 'Réponses au formulaire : Fichier Données', link: editMode.data.form_data_file}]"
                    appDragDrop>
                  </app-drag-drop-file-input>
                  <br>
                </div>
              </ng-container>

              <ng-container *ngIf="editMode.active && editMode.data && editMode.data.second_form_data_file">
                <div class="col-lg-6 col-md-12">
                  <div class="form-group">
                    <label>Réponses au formulaire : IBAN</label>
                    <app-drag-drop-file-input
                      [limit]="0"
                      [forceTypes]="null"
                      [removeDeleteAction]="true"
                      [list]="[{name: 'Réponses au formulaire : Fichier IBAN', link: editMode.data.second_form_data_file}]"
                      appDragDrop></app-drag-drop-file-input>
                  </div>
                </div>
              </ng-container>

              <button type="submit" [disabled]="editFormGroup.invalid" *ngIf="!loadingService.loading; else templateSubmit"
                class="ppp-button ppp-button-black btn btn-primary btn-round">
                <ng-container *ngIf="(editMode.active && editMode.data); else creationTemplateText">Mettre à jour</ng-container>
                <ng-template #creationTemplateText>Créer</ng-template>
              </button>
              <ng-template #templateSubmit>
                <button
                  disabled="true"
                  class="ppp-button ppp-button-black btn btn-primary btn-round" (click)="$event.preventDefault()">
                  ...
                </button>
              </ng-template>

            </div>
          </div>
        </form>
      </div>
    </div>
  </ng-container>
</ng-container>
<ng-template #templateLoading>
  <app-loading></app-loading>
</ng-template>
