import { Injectable } from '@angular/core';
import { convertFileToBase64 } from '../helpers/convertFiles';
import 'bootstrap-notify';
import { ApiService } from './api.service';
import { CommercialAction } from '../models/CommercialAction';

function isFloat(num) {
  return num % 1 !== 0;
}

@Injectable({ providedIn: 'root' })
export class CommercialActionsService {
  constructor(private apiService: ApiService) { }

  getById(actionId) {
    return new Promise((resolve, reject) => {
      this.apiService.get('commercial_actions/' + actionId)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getAll() {
    return new Promise((resolve, reject) => {
      this.apiService.get('commercial_actions')
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async formatData(commercialAction: CommercialAction) {
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();
      formData.append('commercial_action:name', commercialAction.name ? commercialAction.name : '');
      formData.append('commercial_action:budget', commercialAction.budget ? commercialAction.budget.toString() : '0');
      formData.append('commercial_action:start_at', commercialAction.start_at.toString());
      formData.append('commercial_action:end_at', commercialAction.end_at.toString());
      formData.append('commercial_action:validity_deadline_date', commercialAction.validity_deadline_date ? commercialAction.validity_deadline_date.toString() : '');
      formData.append('commercial_action:universe', commercialAction.universe.toString());
      formData.append('commercial_action:action_type', commercialAction.action_type.toString() ? commercialAction.action_type.toString() : '0');
      formData.append('commercial_action:automatic_emails', commercialAction.automatic_emails.toString() ? commercialAction.automatic_emails.toString() : "false");
      formData.append('commercial_action:rules', commercialAction.rules ? commercialAction.rules : '');
      formData.append('commercial_action:deferred_actions_number', commercialAction.deferred_actions_number ? commercialAction.deferred_actions_number.toString() : '0');
      formData.append('commercial_action:immediate_actions_number', commercialAction.immediate_actions_number ? commercialAction.immediate_actions_number.toString() : '0');
      formData.append('commercial_action_adherents', JSON.stringify(commercialAction.commercial_action_adherents));
      if (commercialAction.adherent_id) {
        formData.append('commercial_action:adherent_id', JSON.stringify(commercialAction.adherent_id));
      }
      formData.append('commercial_action_user_fields', JSON.stringify(commercialAction.commercial_action_user_fields));
      formData.append('commercial_action_general_fields', JSON.stringify(commercialAction.commercial_action_general_fields));
      formData.append('commercial_action:user_ids', commercialAction.users ? commercialAction.users.join(',') : '');

      if (commercialAction.banner !== null) {
        // TODO: NOT SEND BASE64 FILE !
        await convertFileToBase64(commercialAction.banner)
          .then((response: string) => {
            formData.append('commercial_action:banner', response);
          })
          .catch(err => {
            formData.append('commercial_action:banner', '');
          })
      }

      if (commercialAction.data_file !== null) {
        await convertFileToBase64(commercialAction.data_file).then((response: string) => {
          formData.append('commercial_action:data_file', response);
        });
      }
      resolve(formData);
    });
  }

  edit(commercialAction: CommercialAction) {
    return new Promise((resolve, reject) => {
      this.formatData(commercialAction).then((formData) => {
        if (commercialAction.id) {
          this.apiService.put('commercial_actions/' + commercialAction.id, formData)
          .then((response: any) => {
            resolve(true);
          })
          .catch((err) => {
            reject(err);
          });
        } else {
          this.apiService.post('commercial_actions', formData)
          .then((response: any) => {
            resolve(true);
          })
          .catch((err) => {
            reject(err);
          });
        }
      });
    });
  }

  delete(actionId) {
    return new Promise((resolve, reject) => {
      this.apiService.delete('commercial_actions/' + actionId)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getAggreementCount(actionId) {
    return new Promise((resolve, reject) => {
      this.apiService.get('commercial_actions/' + actionId + '/agreements_count')
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    })
  }

  estimate(actionId: number, season: string, diameter: number, tiresCount: number, axles?: Array<{name: string, value: any}>) {
    const formData = new FormData();
    formData.append('data', JSON.stringify({
      general_season: season,
      general_diameter: isFloat(diameter) ? parseFloat(diameter.toString()) : Number(diameter),
      general_tire_count: Number(tiresCount),
      general_axles: axles
    }));
    return new Promise((resolve, reject) => {
      this.apiService.post('commercial_actions/' + actionId + '/estimate', formData)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

}
