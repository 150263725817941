/* GENERAL */
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
registerLocaleData(localeFr, 'fr');

/* MISC */
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { LoginComponent } from './views/login/login.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';

/* SERVICES  */

/* HELP */
import { HelpComponent } from './views/help/help.component';

/* MODULE: PROFIL PLUS AGENCES */
import { SharedModule } from './shared.module';
import { ErrorPageComponent } from './views/error-page/error-page.component';
import { GestionApaModule } from './views/gestion-apa/gestion-apa.module';
import { CommercialActionsModule } from './views/commercial-actions/commercial-actions.module';
import { UsersModule } from './views/users/users.module';
import { ProfilplusDirectModule } from './views/profilplus-direct/profilplus-direct.module'
import { ProfilplusSiteModule } from './views/profilplus-site/profilplus-site.module';
import { MyprofilplusModule } from './views/myprofilplus/myprofilplus.module';
import { ProfilplusAgencesModule } from './views/profilplus-agences/profilplus-agences.module';
import { NotificiationsComponent } from './views/notificiations/notificiations.component';
import { EmailsSmsHistoryModule } from './views/emails-sms-history/emails-sms-history.module';


// APP SIGNAL
import Appsignal from '@appsignal/javascript';
import { createErrorHandlerFactory } from '@appsignal/angular';
import { ErrorHandler } from '@angular/core';
import config from './config';
import { AlertComponent } from './components/alert/alert.component';
import { MeetingsModule } from './views/meetings/meetings.module';
import { ActivityTrackingModule } from './views/activity-tracking/activity-tracking.module';
import { JobModule } from './views/job/job.module';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';

const appsignal = new Appsignal({
  key: config.appSignalFrontApiKey,
  revision: "0.1"
});

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    LoginComponent,
    ErrorPageComponent,
    HelpComponent,
    NotificiationsComponent,
    AlertComponent
  ],
  imports: [
    SharedModule,
    ActivityTrackingModule,
    UsersModule,
    GestionApaModule,
    CommercialActionsModule,
    ProfilplusDirectModule,
    ProfilplusSiteModule,
    MyprofilplusModule,
    ProfilplusAgencesModule,
    EmailsSmsHistoryModule,
    MeetingsModule,
    JobModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    CKEditorModule,
    SweetAlert2Module.forRoot()
  ],
  providers: [
    {provide: LOCALE_ID, useValue: 'fr' },
    {
      provide: ErrorHandler,
      useFactory: createErrorHandlerFactory(appsignal)
    }
  ],
  bootstrap: [AppComponent],

})
export class AppModule { }
