<app-topbar moduleSlug="commercial-actions">
  <ng-container title>
    <nav aria-label="breadcrumb" role="navigation">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['/']">Tableau de bord</a></li>
        <li class="breadcrumb-item"><a [routerLink]="['/commercial-actions']">Actions commerciales</a></li>
        <li class="breadcrumb-item active"><a href="javascript:;">Liste des demandes d'accord</a></li>
      </ol>
    </nav>
  </ng-container>
</app-topbar>

<div class="row" *ngIf="policiesService.currentUser.policies.agreement.create">
  <div class="col-md-12">
    <a class="ppp-button ppp-button-black btn btn-primary btn-round"
    [routerLink]="['/commercial-actions/agreement']">
      <i class="material-icons">library_add</i>
      Créer une nouvelle demande d'accord
    </a>
  </div>
</div>

<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-header card-header-rose card-header-icon">
        <div class="card-icon">
          <i class="material-icons">list</i>
        </div>
        <h4 class="card-title">Liste des demandes d'accord</h4>
      </div>
      <div class="card-body">

        <app-filter-bar [filters]="filters"
          (changed)="setPage({offset: 0}, null, $event)">
          <p style="margin-top: 10px;" *ngIf="loadingList">chargement...</p>
        </app-filter-bar>

        <div class="table-responsive">
          <ngx-datatable #datatable class="material" [rows]="items" [columnMode]="'standard'" [rowHeight]="'auto'" [headerHeight]="50" [footerHeight]="50" [externalPaging]="true" [externalSorting]="true" [loadingIndicator]="loadingList" [reorderable]="false" (page)="setPage($event)" (sort)="onSort($event)">

            <!-- DATE CRÉATION -->
            <ngx-datatable-column name="Date création" prop="created_at" [width]="150">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{ value | date: 'dd/MM/yy' }}
              </ng-template>
            </ngx-datatable-column>

            <!-- N° ACCORD -->
            <ngx-datatable-column name="N° accord" prop="commercial_action_agreement_number" [width]="110">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{ row.commercial_action.id }}-{{ row.id }}
              </ng-template>
            </ngx-datatable-column>

            <!-- ACTION COMMERCIALE -->
            <ngx-datatable-column name="Action commerciale" prop="commercial_action" [width]="220">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <a title="Voir l'action commerciale" class="ppp-link" target="_blank" [routerLink]="['/commercial-actions/edit/', value.id]" *ngIf="policiesService.currentUser.policies['commercial-action'] && policiesService.currentUser.policies['commercial-action'].update; else commercialActionOnlyData">
                  {{ value.name }}
                </a>
                <ng-template #commercialActionOnlyData>
                  {{ value.name }}
                </ng-template>
              </ng-template>
            </ngx-datatable-column>

            <!-- ADHÉRENT -->
            <ngx-datatable-column name="Adhérent" prop="adherent_name" [width]="190" *ngIf="user.is_admin || user.adherents.length > 0">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <ng-container *ngIf="row.agency && row.agency.adherent">
                  <a title="Voir l'adhérent" class="ppp-link" target="_blank" [routerLink]="['/adherents-companies-management', (row.agency.adherent.is_closed ? 'closed' : 'open'), 'edit', row.agency.adherent.id]" *ngIf="policiesService.currentUser.policies.adherent && policiesService.currentUser.policies.adherent.update; else adherentOnlyData">
                    {{ row.agency.adherent.name }}
                  </a>
                  <ng-template #adherentOnlyData>
                    {{ row.agency.adherent.name }}
                  </ng-template>
                </ng-container>
              </ng-template>
            </ngx-datatable-column>

            <!-- AGENCE ÉMETTRICE -->
            <ngx-datatable-column name="Agence émettrice" prop="agency_name" [width]="190">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <ng-container *ngIf="row.agency">
                  <a title="Voir l'agence" class="ppp-link" target="_blank"
                    [routerLink]="['/profilplus-agences/edit/', row.agency.id]"
                    *ngIf="policiesService.currentUser.policies['open-agency']
                    && policiesService.currentUser.policies['open-agency'].update; else agencyOnlyData">
                    {{ row.agency.name }}
                  </a>
                  <ng-template #agencyOnlyData>
                    {{ row.agency.name }}
                  </ng-template>
                </ng-container>
              </ng-template>
            </ngx-datatable-column>

            <!-- CODE -->
            <!-- Pour les AC "Avec utilisation d'un fichier de codes promotionnels" (1) + "Avec génération et saisie de codes promotionnels" (2) + "Avec génération d'un code, envoi SMS et saisie du code" (3) -->
            <!-- Affichage du code promotionnel -->
            <ngx-datatable-column name="Code" prop="data" [width]="140">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <ng-container *ngIf="[1, 2, 3].includes(row.commercial_action.action_type)">
                  <span class="slashed_zero">{{ value?.general_promo_code }}</span>
                </ng-container>
              </ng-template>
            </ngx-datatable-column>

            <!-- STATUT -->
            <ngx-datatable-column name="Statut" prop="data" [width]="140">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <!-- Pour les AC "Avec génération et saisie de codes promotionnels" (2) + "Avec génération d'un code, envoi SMS et saisie du code" (3) -->
                <!-- Affichage de "Code saisi" ou de "Code généré", selon l'état du code promotionnel -->
                <ng-container *ngIf="[2, 3].includes(row.commercial_action.action_type)">
                  {{ value.promo_code_used ? 'Code saisi' : 'Code généré' }}
                </ng-container>
                <!-- Pour les AC "Avec utilisation d'un fichier de codes promotionnels" (1) -->
                <!-- Affichage de "Code saisi" -->
                <ng-container *ngIf="row.commercial_action.action_type == 1">
                    Code saisi
                </ng-container>
              </ng-template>
            </ngx-datatable-column>

            <!-- NOM CLIENT -->
            <ngx-datatable-column name="Nom client" prop="customer_last_name" [width]="160">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <ng-container  *ngIf="row.customer">
                  {{ row.customer.last_name }}
                </ng-container>
              </ng-template>
            </ngx-datatable-column>

            <!-- PRÉNOM CLIENT -->
            <ngx-datatable-column name="Prénom client" prop="customer_first_name" [width]="160">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <ng-container  *ngIf="row.customer">
                  {{ row.customer.first_name }}
                </ng-container>
              </ng-template>
            </ngx-datatable-column>

            <!-- EMAIL CLIENT -->
            <ngx-datatable-column name="Email client" prop="customer_email" [width]="160">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <ng-container  *ngIf="row.customer">
                  {{ row.customer.email }}
                </ng-container>
              </ng-template>
            </ngx-datatable-column>

            <!-- N° FACTURE (CRÉATION) -->
            <!-- Pour les AC "Avec génération d'un code, envoi SMS et saisie du code" (3) -->
            <ngx-datatable-column name="N° facture (création)" prop="data" [width]="200">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <ng-container *ngIf="row.commercial_action.action_type == 3">
                  {{ value?.customer?.invoice_number }}
                </ng-container>
              </ng-template>
            </ngx-datatable-column>

            <!-- N° FACTURE (SAISIE) -->
            <!-- Pour les AC "Avec génération d'un code, envoi SMS et saisie du code" (3) -->
            <ngx-datatable-column name="N° facture (saisie)" prop="data" [width]="200">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <ng-container *ngIf="row.commercial_action.action_type == 3">
                  {{ value?.customer?.invoice_number_entered }}
                </ng-container>
              </ng-template>
            </ngx-datatable-column>

            <!-- MONTANT REMISE -->
            <ngx-datatable-column name="Montant remise" prop="amount" [width]="170">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <!-- Pour les AC "Standard" (0) + "Avec utilisation d'un fichier de codes promotionnels" (1) -->
                <!-- Affichage du montant de la remise en HT ou TTC, selon l'univers -->
                <ng-container *ngIf="[0, 1].includes(row.commercial_action.action_type)">
                  {{ row.amount | currency:"EUR" }}
                  {{ row.commercial_action.universe.name === "b2c_vl" ? " TTC" : " HT"}}
                </ng-container>
                <!-- Pour les AC "Avec génération d'un code, envoi SMS et saisie du code" (3) -->
                <!-- Affichage du montant de la remise en HT ou TTC, selon l'univers -->
                <ng-container *ngIf="row.commercial_action.action_type === 3">
                  {{ getCouponAmount(row, true) }} € TTC <br>
                  {{ getCouponAmount(row, false) }} € HT
                </ng-container>
              </ng-template>
            </ngx-datatable-column>


            <!-- DATE LIMITE DE VALIDITÉ DU CODE -->
            <!-- Concerne les AC "Avec génération d'un code, envoi SMS et saisie du code" (3) -->
            <ngx-datatable-column name="Date limite" prop="commercial_action" [width]="145">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <ng-container *ngIf="row.commercial_action.id == 92 && compareDate(row.created_at, '08/31/2023'); else normalLimitDate">
                  31/12/2023
                </ng-container>
                <ng-template #normalLimitDate>
                  {{ value?.validity_deadline_date  | date: 'dd/MM/yy' }}
                </ng-template>
              </ng-template>
            </ngx-datatable-column>

            <!-- ACTIONS -->
            <ngx-datatable-column name="Actions" [sortable]="false">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <a [routerLink]="['/commercial-actions/', 'agreements', row.commercial_action.id , row.id, 'consultation']" class="btn btn-success" title="Consulter">
                  <i class="material-icons">visibility</i>
                </a>
                <button *ngIf="policiesService.currentUser.policies.agreement.delete && (user.is_admin || (user.adherents && user.adherents.length > 0))" type="button" class="btn btn-danger" title="Supprimer">
                  <i class="material-icons" (click)="onDelete(row.commercial_action.id, row.id)">close</i>
                </button>
              </ng-template>
            </ngx-datatable-column>

          </ngx-datatable>
        </div>

      </div>
    </div>
  </div>
</div>
