<ng-container *ngIf="modules[currentModule] && currentService.slug && currentItem">
  <app-topbar moduleSlug="commercial-actions">
    <ng-container title>
      <nav aria-label="breadcrumb" role="navigation">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a [routerLink]="['/']">Tableau de bord</a></li>
          <li class="breadcrumb-item"><a [routerLink]="['/'+currentModule]">{{modules[currentModule].name}}</a></li>
          <li class="breadcrumb-item"><a [routerLink]="['/'+currentModule, currentService.slug]">Liste des {{currentService.name}}</a></li>
          <li *ngIf="currentService.sub" class="breadcrumb-item">
            <a [routerLink]="['/'+currentModule,  currentService.slug, currentService.sub.slug]">{{currentService.sub.name}}</a>
          </li>
          <li class="breadcrumb-item active"><a href="javascript:;">Détails accord n°{{commercialActionId}}-{{agreementId}}</a></li>
        </ol>
      </nav>
    </ng-container>
  </app-topbar>

  <div class="row">
    <div class="col-md-6">
      <a class="ppp-button ppp-button-black btn btn-primary btn-round" [routerLink]="['/commercial-actions/agreement']">
        <i class="material-icons">library_add</i>
        Créer une nouvelle demande d'accord
      </a>
      <a class="ppp-button ppp-button-black btn btn-primary btn-round"
          *ngIf="currentItem.commercial_action && currentItem.commercial_action.action_type === 3 && currentItem.data.promo_code_used === false" [routerLink]="['/commercial-actions/agreements', 'edit', 'action', currentItem.commercial_action.id, 'agreement', currentItem.agency_id, currentItem.data.general_promo_code]">
        <i class="material-icons">library_add</i>
        Saisir un accord en utilisant le code promotionnel généré
      </a>
    </div>
  </div>

  <app-show-content [fields]="fields" [item]="currentItem">
    <ng-template #template let-default let-item="item" let-field="field">

      <!-- N° ACCORD -->
      <ng-container *ngIf="field.slug === 'agreement_number'">
        <strong>N° accord : </strong> <strong class="description">{{commercialActionId}}-{{agreementId}}</strong>
      </ng-container>

      <!-- ACTION COMMERCIALE -->
      <ng-container *ngIf="field.slug ==='commercial_action' && item.commercial_action">
        <strong>Action commerciale :</strong>
        {{item.commercial_action.name}}
      </ng-container>

      <!-- ÉTAT DU CODE (SAISI OU NON-SAISI) -->
      <!-- Pour les AC "Avec utilisation d'un fichier de codes promotionnels" (1) + "Avec génération et saisie de codes promotionnels" (2) + "Avec génération d'un code, envoi SMS et saisie du code" (3) -->
      <ng-container *ngIf="field.slug === 'promo_code' && item.commercial_action && [1, 2, 3].includes(item.commercial_action.action_type)">
        <ng-container [ngSwitch]="item.commercial_action.action_type">

          <!-- Pour les AC "Avec utilisation d'un fichier de codes promotionnels" (1) -->
          <!-- Code saisi -->
          <ng-container *ngSwitchCase="1">
            <strong class="description">Code bien saisi ! <span class="slashed_zero">({{item.data.general_promo_code}})</span></strong>
          </ng-container>

          <!-- Pour les AC "Avec génération et saisie de codes promotionnels" (2) -->
          <ng-container *ngSwitchCase="2">
            <!-- Code saisi -->
            <ng-container *ngIf="item.data.promo_code_used; else generatedElse">
              <strong class="description">BON D'ACHAT PRIS EN COMPTE : saisie du code <span class="slashed_zero">{{item.data.general_promo_code}}</span>
                <ng-container *ngIf="item.updated_at != item.created_at">
                  le {{item.updated_at | date: 'dd/MM/yyyy - HH:mm:ss' }}
                </ng-container>
              </strong>
            </ng-container>
            <!-- Code généré (non saisi) -->
            <ng-template #generatedElse>
              <strong>Code généré</strong> : <span class="slashed_zero">{{item.data.general_promo_code}}</span>
            </ng-template>
          </ng-container>

          <!-- Pour les AC "Avec génération d'un code, envoi SMS et saisie du code" (3) -->
          <ng-container *ngSwitchCase="3">
            <!-- Code saisi -->
            <ng-container *ngIf="item.data.promo_code_used; else generatedElse">
              <strong class="description">BON D'ACHAT PRIS EN COMPTE : saisie du code <span class="slashed_zero">{{item.data.general_promo_code}}</span>
                <ng-container *ngIf="item.updated_at != item.created_at">
                  le {{item.updated_at | date: 'dd/MM/yyyy - HH:mm:ss' }}
                </ng-container>
              </strong>
            </ng-container>
            <!-- Code généré (non saisi) -->
            <ng-template #generatedElse>
              <strong>Code généré</strong> : <span class="slashed_zero">{{item.data.general_promo_code}}</span><br>
              <strong class="description promoCode">N'OUBLIEZ PAS ! CE CODE EST UTILISABLE DÈS MAINTENANT POUR TOUS PRODUITS ET SERVICES LIÉS À UN VÉHICULE LÉGER DE MOINS DE 3,5 T.</strong>
            </ng-template>
            <br/>
            <ng-container *ngIf="item.commercial_action.id == 92 && item.commercial_action.created_at < limitDateForAc92">
              <strong class="description">Code/bon d'achat valable jusqu'au : 31/12/2023</strong>
            </ng-container>
            <ng-container *ngIf="item.commercial_action.id == 92 && item.commercial_action.created_at >= limitDateForAc92">
              <strong class="description">Code/bon d'achat valable jusqu'au : 30/06/2024</strong>
            </ng-container>
            <ng-container *ngIf="item.commercial_action.id != 92">
              <strong class="description">Code/bon d'achat valable jusqu'au : {{ item.commercial_action.validity_deadline_date | date: 'dd/MM/yyyy' }}</strong>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-container>

      <!-- AGENCE ÉMETTRICE -->
      <ng-container *ngIf="field.slug ==='agency' && item.agency_id">
        <strong>Agence émettrice :</strong>
        {{ getAgency(item.agency_id) ? getAgency(item.agency_id).name : '' }}
      </ng-container>

      <!-- MONTANT À REMBOURSER (HT) -->
      <!-- Pour les AC "Standard" (0) -->
      <ng-container *ngIf="field.slug ==='amount_ht' && item.commercial_action.action_type == 0">
        <strong>Montant à rembourser HT :</strong>
        <ng-container *ngIf="item.commercial_action.universe.name === 'b2c_vl'; else elseVl">
          {{(item.amount / 1.2) | currency:"EUR"}} HT
        </ng-container>
        <ng-template #elseVl>
          {{(item.amount) | currency:"EUR"}}
        </ng-template>
      </ng-container>

      <!-- MONTANT À REMBOURSER (TTC) -->
      <!-- Pour les AC "Standard" (0) -->
      <ng-container *ngIf="field.slug ==='amount_ttc'  && item.commercial_action.action_type == 0">
        <ng-container *ngIf="item.commercial_action.universe.name === 'b2c_vl';else vlttc">
          <strong>Montant à rembourser TTC :</strong>
          {{item.amount | currency:"EUR"}} TTC
        </ng-container>
        <ng-template #vlttc>
          <strong>Montant à rembourser TTC :</strong>
          {{(item.amount * 1.2) | currency:"EUR"}} TTC
        </ng-template>
      </ng-container>

      <!-- PRÉNOM CLIENT -->
      <ng-container *ngIf="field.slug ==='customer_firstname' && item.customer && item.customer.first_name">
        <strong>Prénom client :</strong>
        {{item.customer.first_name}}
      </ng-container>

      <!-- NOM CLIENT -->
      <ng-container *ngIf="field.slug ==='customer_lastname' && item.customer && item.customer.last_name">
        <strong>Nom client :</strong>
        {{item.customer.last_name}}
      </ng-container>

      <!-- TÉLÉPHONE PORTABLE DU CLIENT -->
      <ng-container *ngIf="field.slug ==='customer_number' && item.customer && item.customer.phone">
        <strong>Téléphone portable client :</strong>
        {{item.customer.phone}}
      </ng-container>

      <!-- EMAIL CLIENT -->
      <ng-container *ngIf="field.slug ==='customer_email' && item.customer && item.customer.email">
        <strong>Email client :</strong>
        {{item.customer.email}}
      </ng-container>

      <ng-container *ngIf="field.slug ==='price_unit_ttc' && ![1, 3].includes(item.commercial_action.action_type) && item.commercial_action.universe.name != 'b2b_pl'">
        <strong>{{field.name}} :</strong>
        {{item.data.general_price_unit_ttc}} €
      </ng-container>

      <ng-container *ngIf="field.slug === 'price_unit_ht' && ![1, 3].includes(item.commercial_action.action_type) && item.commercial_action.universe.name != 'b2b_pl'">
        <strong>{{field.name}} :</strong>
        {{item.data.general_price_unit_ht}} €
      </ng-container>

      <!-- NOMBRE DE PNEUS -->
      <!-- Pour les AC "Standard" (0) + "Avec génération et saisie de codes promotionnels" (2) + "Avec génération d'un code, envoi SMS et saisie du code" (3) et non liées à l'univers B2B PL -->
      <ng-container *ngIf="field.slug ==='tire_count' && item.commercial_action.action_type != 1 && item.commercial_action.universe.name != 'b2b_pl'">
        <strong>{{field.name}} :</strong>
        {{item.data.general_tire_count}}
      </ng-container>

      <!-- NUMÉRO FACTURE (CRÉATION) -->
      <ng-container *ngIf="field.slug ==='invoice_number' && item.customer && item.customer.invoice_number">
        <strong>Numéro facture (création) :</strong>
        {{item.customer.invoice_number}}
      </ng-container>

      <!-- NUMÉRO FACTURE (SAISI) -->
      <ng-container *ngIf="field.slug ==='invoice_number_entered' && item.customer && item.customer.invoice_number_entered">
        <strong>Numéro facture (saisi) :</strong>
        {{item.customer.invoice_number_entered}}
      </ng-container>

      <!-- MARQUE -->
      <ng-container *ngIf="field.slug ==='brand_name' && item.data && item.data.general_tire_brand">
        <strong>Marque :</strong>
        {{getBrandName()}}
      </ng-container>

      <!-- MONTANT DU BON D'ACHAT (TTC) -->
      <!-- Pour les AC "Avec utilisation d'un fichier de codes promotionnels" (1) + "Avec génération d'un code, envoi SMS et saisie du code" (3) -->
      <ng-container *ngIf="field.slug === 'coupon_amount_ttc' && item.commercial_action && [1, 3].includes(item.commercial_action.action_type)">
        <strong>Montant du bon d'achat (TTC) :</strong>
        <ng-container *ngIf="item.commercial_action.action_type === 3; else elseAmount">
          {{getCouponAmount(true)}} €
        </ng-container>
        <ng-template #elseAmount>
          {{item.amount}} €
        </ng-template>
      </ng-container>

      <!-- MONTANT DU BON D'ACHAT (HT) -->
      <!-- Pour les AC "Avec génération d'un code, envoi SMS et saisie du code" (3) -->
      <ng-container *ngIf="field.slug === 'coupon_amount_ht' && item.commercial_action && item.commercial_action.action_type === 3">
        <strong>Montant du bon d'achat (HT) :</strong>
        {{getCouponAmount()}} €
      </ng-container>

    </ng-template>
  </app-show-content>

  <div>
    <a [routerLink]="['/'+currentModule, currentService.slug]" type="button" class="ppp-button ppp-button-gray btn btn-primary btn-round mr-2">Retour</a>
    <a *ngIf="currentItem.commercial_action.action_type === 2" (click)="openPDF()" type="button" class="ppp-button ppp-button-black btn btn-primary btn-round mr-2">
      <i class="material-icons">picture_as_pdf</i> Télécharger le bon d'achat en .pdf
    </a>
  </div>

</ng-container>
