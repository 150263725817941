import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommercialActionsService } from 'src/app/services/commercial-actions.service';
import { CommercialAction } from 'src/app/models/CommercialAction';
import { User } from 'src/app/models/User';
import { AlertService } from 'src/app/services/alert.service';
import { AgenciesService } from 'src/app/services/agencies.service';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AgreementsService } from 'src/app/services/agreements.service';
import { AuthService } from 'src/app/services/auth.service';
import Swal from 'sweetalert2';
import { EnumsService } from 'src/app/services/enums.service';
import { LoadingService } from 'src/app/services/loading.service';

function agencyConditionallyRequiredValidator(formControl: AbstractControl) {
  if (!formControl.parent) {
    return null;
  }
  if (this.preselectCommercialAction && this.preselectCommercialAction.action_type === 0) {
    return Validators.required(formControl);
  }
  return null;
}

@Component({
  selector: 'app-agreements-edit',
  templateUrl: './agreements-edit.component.html',
  styleUrls: ['./agreements-edit.component.scss']
})
export class AgreementsEditComponent implements OnInit {
  public user: User;
  public submitted: boolean = false;
  public editFormGroup: UntypedFormGroup;

  public loading: boolean = false;
  loadingCommercialActionCount = false
  public actionId = null;
  public agreementId = null;
  public commercialActions: Array<CommercialAction> = [];
  public preselectCommercialActionIndex: number;
  public preselectCommercialAction: CommercialAction = null;

  public defaultAgenciesList: Array<any> = [];

  public agenciesList: Array<any> = [];
  public tiresList: Array<any> = null;
  public tiresListSpecial: Array<any> = null;
  public diametersList: Array<any> = null;
  public preselectAgency: any = null;
  public preselectTire: any;
  public editMode: any = {
    active: false,
    data: null
  };

  public displayDiscount: boolean = false;
  public discountEstimation: number = null;

  public actionType2Choice = 0; // 0 generate | 1 type    code

  public customerFields: Array<{slug: string, label: string, type: string, options?: any}> = [
    {
      slug: 'siret',
      label: 'Siret',
      type: 'siret'
    },
    {
      slug: 'loyalty_card_number',
      label: 'Numero de compte de fidélité',
      type: 'text'
    },
    {
      slug: 'civility',
      label: 'Civilité',
      type: 'radio',
      options: [
        {
          value: 1,
          label: 'Madame'
        },
        {
          value: 3,
          label: 'Monsieur'
        }
      ]
    },
    {
      slug: 'address',
      label: 'Adresse',
      type: 'address'
    },
    {
      slug: 'society',
      label: 'Société',
      type: 'text'
    },
    {
      slug: 'first_name',
      label: 'Prénom',
      type: 'text'
    },
    {
      slug: 'last_name',
      label: 'Nom',
      type: 'text'
    },
    {
      slug: 'birthday',
      label: 'Date de naissance',
      type: 'date'
    },
    {
      slug: 'phone',
      label: 'Téléphone portable',
      type: 'phone'
    },
    {
      slug: 'email',
      label: 'Email',
      type: 'email'
    },
    {
      slug: 'invoice_number',
      label: 'Numéro facture',
      type: 'text'
    }
  ];

  // TODO/Notes: Essieux T was renamed to 'Nombre de pneus' specifically for AC 103
  public generalFields: Array<{slug: string, label: string, type: string, options?: any}> = [
    {
      slug: 'tire_count',
      label: 'Nombre de pneus',
      type: 'custom'
    },
    {
      slug: 'price_unit_ht',
      label: 'Prix unitaire du pneu (HT)',
      type: 'number'
    },
    {
      slug: 'price_unit_ttc',
      label: 'Prix unitaire du pneu (TTC)',
      type: 'number'
    },
    {
      slug: 'general_diameter',
      label: 'Diamètre de pneu',
      type: 'custom'
    },
    {
      slug: 'width',
      label: 'Largeur de pneu',
      type: 'number'
    },
    {
      slug: 'tire_brand',
      label: 'Marque de pneu',
      type: 'custom'
    },
    {
      slug: 'tire_brand_b_g_d',
      label: 'Marque de véhicule',
      type: 'custom'
    },
    {
      slug: 'vehicle_brand',
      label: 'Marque de véhicule',
      type: 'text'
    },
    {
      slug: 'fiscal_horse',
      label: 'Chevaux fiscaux du véhicule',
      type: 'number'
    },
    {
      slug: 'axle_t',
      label: 'Nombre de pneus',
      type: 'number'
    },
    {
      slug: 'axle_s',
      label: 'Essieux S',
      type: 'number'
    },
    {
      slug: 'axle_d',
      label: 'Essieux D',
      type: 'number'
    },
    {
      slug: 'season',
      label: 'Saison',
      type: 'list',
      options: [
        {label: "4 saisons", value: 'allseasons'},
        {label: "Hiver", value: 'winter'},
        {label: "Ete", value: 'summer'}
      ]
    }
  ];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private commercialActionsService: CommercialActionsService,
    private agreementsService: AgreementsService,
    public authService: AuthService,
    private alertService: AlertService,
    private enumsService: EnumsService,
    private agenciesService: AgenciesService,
    public loadingService: LoadingService) {
      this.loading = true;

      this.enumsService.observable.subscribe({
        complete: () => {
          this.user = this.authService.currentUserValue;
          if (this.route.snapshot.params.action_id) {
            this.actionId = parseInt(this.route.snapshot.params.action_id);
            this.agreementId = parseInt(this.route.snapshot.params.agreement_id);
          }

          this.tiresList = this.enumsService.enums.brands;
          this.tiresListSpecial = this.enumsService.enums.brands.filter(b => ['Bridgestone', 'Goodyear', 'Dunlop'].includes(b.name));

          this.agenciesService.getAll().then((responseAgencies: Array<any>) => {
            this.defaultAgenciesList = responseAgencies; // FOR LATER
            this.onLoadCommercialActions(() => {
              this.loading = false;

              // init form
              this.editFormGroup = this.formBuilder.group({
                customer_siret: ['', null],
                customer_agency: ['', agencyConditionallyRequiredValidator],
                customer_loyalty_card_number: [null, null],
                customer_first_name: ['', null],
                customer_last_name: ['', null],
                customer_email: ['', Validators.email],
                customer_civility: ['', null],
                customer_society: ['', null],
                customer_birthday: [null, null],
                customer_address: [null, null],
                customer_address_add: [null, null],
                customer_address_postal_code: [null, null],
                customer_address_city: [null, null],
                customer_invoice_number: [null, null],
                customer_invoice_number_entered: [null, null],
                customer_phone: ['', null],
                general_tire_count: [null, null],
                general_price_unit_ht: [null, null],
                general_price_unit_ttc: [null, null],
                general_diameter: [null, null],
                general_width: [null, null],
                general_tire_brand: [null, null],
                general_tire_brand_b_g_d: [null, null],
                general_vehicle_brand: [null, null],
                general_fiscal_horse: [null, null],
                general_axle_t: [null, null],
                general_axle_s: [null, null],
                general_axle_d: [null, null],
                general_season: [null, null],
                promo_code: [null, null]
              }, {
                validators: [agencyConditionallyRequiredValidator]
              });

              // Select action if there is the action_id params
              if (this.route.snapshot.params.action_id) {
                this.selectAction();

                // Action type 3 preset for agency_id and code_promo
                if (this.preselectCommercialAction.action_type === 3 && this.route.snapshot.params.agency_id && this.route.snapshot.params.code_promo) {
                  this.actionType2Choice = 1
                  this.updateForm()
                  const agencyIndex = this.defaultAgenciesList.findIndex(a => a.id == this.route.snapshot.params.agency_id)
                  this.preselectAgency = agencyIndex != -1 ? agencyIndex : null
                  this.editFormGroup.controls.customer_agency.patchValue(this.defaultAgenciesList[this.preselectAgency].id)
                  this.editFormGroup.controls.promo_code.patchValue(this.route.snapshot.params.code_promo)
                }
              }
            });
          });
        }
      });
    }

  ngOnInit(): void {}

  ngAfterContentInit() {}

  onLoadCommercialActions(callback) {
    // load commercialAction list
    this.commercialActionsService.getAll()
      .then((response: Array<CommercialAction>) => {
        response.forEach(ac => {
          if (ac.users.includes(this.user.id) || this.user.is_admin) {
            this.commercialActions.push(ac)
          } else if (!this.acIsArchived(ac) && new Date(ac.start_at) <= new Date()) {
            this.commercialActions.push(ac);
          }
        })
        this.prepareAClist(callback);
      })
      .catch((err) => {
        this.alertService.error(err);
      });
  }

  prepareAClist(callback) {
    /* Add archived CA if coming from "Vue maitre" from a specific archived CA */
    if (this.route.snapshot.params.action_id) {
      this.commercialActionsService.getById(this.actionId).then((response2: CommercialAction) => {
        if (response2) {
          if (new Date(response2.end_at) < new Date() && new Date(response2.start_at) <= new Date()) {
            this.commercialActions.push(response2);
          }
        }
        callback();
      })
      .catch((err) => {
        this.alertService.error(err);
      });
    } else {
      callback();
    }
  }

  onSelectedAgency(selection) {
    this.editFormGroup.patchValue({customer_agency: selection.id});
    // Force le mode "Saisir un accord" sur une AC de type 3 archivée
    if(this.preselectCommercialAction.action_type === 3 && this.acIsArchived(this.preselectCommercialAction) && this.preselectCommercialAction.users.includes(this.authService.currentUserValue.id)) {
      this.actionType2Choice = 1
    }
  }

  onSelectedTire(selection) {
    this.editFormGroup.patchValue({general_tire_brand: selection.id});
    this.editFormGroup.patchValue({general_tire_brand_b_g_d: selection.id});
  }

  onSelectedAction(selection) {
    this.actionId = selection.id;
    this.selectAction();
  }

  selectAction() {
    const index = this.commercialActions.findIndex(ca => ca.id === this.actionId);
    if (index !== -1) {
      this.preselectCommercialActionIndex = index;
      this.preselectCommercialAction = this.commercialActions[index];

      // Remove auto-check for AC 92, AC 100 and AC 106 for adherents, because they don't need to generate codes now.
      if (this.preselectCommercialAction.id == 106 && !this.user.is_admin || this.preselectCommercialAction.id == 100 && !this.user.is_admin || this.preselectCommercialAction.id == 92 && !this.user.is_admin) {
        this.actionType2Choice = null;
      }

      this.displayDiscount = false;

      // GET REMAINING INFO
      this.loadingCommercialActionCount = true
      this.commercialActionsService.getAggreementCount(this.preselectCommercialAction.id)
        .then((response: CommercialAction) => {
          this.preselectCommercialAction.commercial_action_national_agreements_amount = response.commercial_action_national_agreements_amount;
          this.preselectCommercialAction.commercial_action_national_agreements_count = response.commercial_action_national_agreements_count;
        })
        .finally(() => {
          this.loadingCommercialActionCount = false
        })

      // PREPARE LIST
      const diametersListIndex = this.preselectCommercialAction.commercial_action_general_fields.findIndex(f => f.slug === 'general_diameter');
      if (diametersListIndex !== -1) {
        this.diametersList = this.preselectCommercialAction.commercial_action_general_fields[diametersListIndex].default_data;
      }

      if (this.editFormGroup) {
        Object.keys(this.editFormGroup.controls).forEach(key => {
          if (key !== 'customer_agency') {
            this.editFormGroup.get(key).setErrors(null) ;
            this.editFormGroup.get(key).clearValidators();
            this.editFormGroup.get(key).updateValueAndValidity();
          }
        });
        this.editFormGroup.updateValueAndValidity();

        this.updateForm()
        if (![0, 2, 3].includes(this.preselectCommercialAction.action_type)) {
          this.editFormGroup.controls['promo_code'].setValidators(Validators.required);
          this.editFormGroup.controls['promo_code'].updateValueAndValidity();
        }
      }

      this.submitted = false;
      this.updateAgenciesList();

      if (this.preselectCommercialAction.action_type === 3) {
        if (this.preselectCommercialAction.id === 92) {
          this.tiresList = this.tiresList.filter(b => ['bridgestone', 'dunlop', 'firestone', 'michelin'].includes(b.name.toLowerCase()))
        } else {
          this.tiresList = this.tiresList.filter(b => ['bridgestone', 'hankook', 'firestone'].includes(b.name.toLowerCase()))
        }
      }
    }
  }

  updateForm() {
    // RESET BUT KEEP AGENCY VALUE
    const agency_value = this.editFormGroup.controls.customer_agency.value
    this.editFormGroup.reset()
    this.editFormGroup.controls.customer_agency.patchValue(agency_value)

    if (this.actionType2Choice === 0) {
      // ADD VALIDATION TO CUSTOMER_ FIELDS
      this.preselectCommercialAction.commercial_action_user_fields.forEach((cuf: {slug: string, required: Boolean}) => {
        if (this.editFormGroup.controls['customer_' + cuf.slug]) {
          if (cuf.required) {
            this.editFormGroup.controls['customer_' + cuf.slug].setValidators(Validators.required);
            // SPECIAL CASE FOR ADDRESS
            if (cuf.slug === 'address') {
              this.editFormGroup.controls['customer_' + cuf.slug].setValidators(Validators.required);
              this.editFormGroup.controls['customer_' + cuf.slug + "_postal_code"].setValidators(Validators.required);
              this.editFormGroup.controls['customer_' + cuf.slug + "_city"].setValidators(Validators.required);
            }
          }
          this.editFormGroup.controls['customer_' + cuf.slug].updateValueAndValidity();
        }
      })

      // ADD VALIDATION TO GENERAL_ FIELDS
      this.preselectCommercialAction.commercial_action_general_fields.forEach((cgf: {slug: string, required: Boolean}) => {
        if (this.editFormGroup.controls['general_' + cgf.slug]) {
          if (cgf.required) {
            this.editFormGroup.controls['general_' + cgf.slug].setValidators(Validators.required);
          } else {
            this.editFormGroup.controls['general_' + cgf.slug].setValidators(null);
          }
          this.editFormGroup.controls['general_' + cgf.slug].updateValueAndValidity();
        }
      });

      // REMOVE PROMO CODE REQUIEREMENT
      this.editFormGroup.controls['promo_code'].patchValue('')
      this.editFormGroup.controls['promo_code'].setValidators(null);
      this.editFormGroup.controls['promo_code'].updateValueAndValidity();

      this.editFormGroup.controls['customer_invoice_number_entered'].setValidators(null);
      this.editFormGroup.controls['customer_invoice_number_entered'].updateValueAndValidity();
    } else {
      // REMOVE ALL VALIDATION ON CUSTOMER_ FIELDS
      this.preselectCommercialAction.commercial_action_user_fields.forEach((cuf: {slug: string, required: Boolean}) => {
        if (this.editFormGroup.controls['customer_' + cuf.slug]) {
          this.editFormGroup.controls['customer_' + cuf.slug].setValidators(null);
          // SPECIAL CASE FOR ADDRESS
          if (cuf.slug === 'address') {
            this.editFormGroup.controls['customer_' + cuf.slug].setValidators(null);
            this.editFormGroup.controls['customer_' + cuf.slug].updateValueAndValidity();
            this.editFormGroup.controls['customer_' + cuf.slug + "_postal_code"].setValidators(null);
            this.editFormGroup.controls['customer_' + cuf.slug + "_postal_code"].updateValueAndValidity();
            this.editFormGroup.controls['customer_' + cuf.slug + "_city"].setValidators(null);
            this.editFormGroup.controls['customer_' + cuf.slug + "_city"].updateValueAndValidity();
          }
          this.editFormGroup.controls['customer_' + cuf.slug].updateValueAndValidity();
        }
      })

      // REMOVE VALIDATION TO GENERAL_ FIELDS
      this.preselectCommercialAction.commercial_action_general_fields.forEach((cgf: {slug: string, required: Boolean}) => {
        if (this.editFormGroup.controls['general_' + cgf.slug]) {
          this.editFormGroup.controls['general_' + cgf.slug].setValidators(null);
          this.editFormGroup.controls['general_' + cgf.slug].updateValueAndValidity();
        }
      });

      // ADD PROMO CODE REQUIEREMENT
      this.editFormGroup.controls['promo_code'].setValidators(Validators.required);
      this.editFormGroup.controls['promo_code'].updateValueAndValidity();

      // Pour les AC de type 3, rend le champ "customer_invoice_number_entered" obligatoire
      if (this.preselectCommercialAction.action_type === 3) {
        this.editFormGroup.controls['customer_invoice_number_entered'].setValidators(Validators.required);
        this.editFormGroup.controls['customer_invoice_number_entered'].updateValueAndValidity();
      }
    }

    // KEEP AGENCY REQUIRED
    this.editFormGroup.controls['customer_agency'].setValidators(Validators.required);
    this.editFormGroup.controls['customer_agency'].updateValueAndValidity();
  }

  updateAgenciesList() {
    // Si l'utilisateur à au moins un adhérent ou une agence (l'agence remonte son adhérent_id dans part_of_adherent_ids)
    if (this.authService.currentUserValue.part_of_adherent_ids &&
      this.authService.currentUserValue.part_of_adherent_ids.length > 0) {

      const temp: Array<any> = this.defaultAgenciesList;
      // Si l'utilisateur n'a pas d'adhérent associé, il a forcément des agences associées car part_of_adherent_ids est rempli
      // Ainsi, on récupère toutes les agences associées et on les ajoute dans la liste des agences sélectionnables

      let res = [];
      if (this.authService.currentUserValue.adherents.length === 0) {
        res = this.authService.currentUserValue.agencies;
      } else {
        // Pour chaque adhérent associée à l'utilisateur,
        // on récupère toutes les agences et on les ajoute dans la liste des agences sélectionnables
        this.authService.currentUserValue.adherents.forEach(ad => {
          res = res.concat(temp.filter(a => a.adherent.id === ad.id));
        });

        // Si l'utilisateur a aussi des agences associées, on les rajoute à la liste des agencess sélectionnables
        if (this.authService.currentUserValue.agencies) {
          res = res.concat(this.authService.currentUserValue.agencies);
        }
      }

      // Si action co est locale, on ne garde que celles qui correspondent
      if (this.preselectCommercialAction.adherent_id) {
        this.agenciesList = res.filter(a => (a.adherent && a.adherent.id === this.preselectCommercialAction.adherent_id)
          || (a.adherent_id && a.adherent_id === this.preselectCommercialAction.adherent_id));
      } else {
        this.agenciesList = res;
      }

      // Si l'utilisateur n'a pas d'adhérent et qu'une seule agence, on l'auto-affecte dans le formulaire
      if (this.authService.currentUserValue.adherents.length === 0
        && this.authService.currentUserValue.agencies.length === 1 && this.editFormGroup) {
        this.preselectAgency = 0;
        this.editFormGroup.patchValue({customer_agency: this.authService.currentUserValue.agencies[0].id});
      }

    } else { // Administrateurs
      if (this.preselectCommercialAction.adherent_id) { // AC locale : les agences concernées
        this.agenciesList = this.defaultAgenciesList.filter(a => (a.adherent && a.adherent.id === this.preselectCommercialAction.adherent_id)
          || (a.adherent_id && a.adherent_id === this.preselectCommercialAction.adherent_id));
      } else {
        this.agenciesList = this.defaultAgenciesList; // AC nationale : toutes les agences PP
      }
    }
  }

  requestDiscount() {
    const type = this.preselectCommercialAction.rules ? this.preselectCommercialAction.rules.split('-')[0] : ''
    const season = this.editFormGroup.value.general_season ? this.editFormGroup.value.general_season : "allseasons"

    this.discountEstimation = 0;
    this.displayDiscount = false;
    switch (type) {
      case "season":
      case 'diameter':
        if (this.editFormGroup.value.general_diameter && this.editFormGroup.value.general_tire_count) {
          this.commercialActionsService.estimate(
            this.preselectCommercialAction.id, season, this.editFormGroup.value.general_diameter, this.editFormGroup.value.general_tire_count
          )
          .then((response: number) => {
            if (response === 0) {
              Swal.fire({ title: 'Aucune remise disponible pour les valeurs saisies.', text: '', icon: 'warning', showCancelButton: false, confirmButtonColor: '#3085d6', cancelButtonColor: '#d33', confirmButtonText: 'ok' })
            } else {
              this.discountEstimation = response;
              this.displayDiscount = true;
            }
          })
          .catch(err => {
            this.alertService.error(err);
          });
        } else {
          Swal.fire({ title: 'Veuillez renseigner tous les champs obligatoires pour interroger.', text: '', icon: 'warning', showCancelButton: false, confirmButtonColor: '#3085d6', cancelButtonColor: '#d33', confirmButtonText: 'ok' });
        }
        break;
      case 'axle':
        let axles: Array<{name:string, value:any}> = [];
        for (const [key, value] of Object.entries(this.editFormGroup.value)) {
          if (key.includes('general_axle_') && this.editFormGroup.value[key]) {
            axles.push({name: key, value:  Number(value)})
          }
        }
        if (axles.length > 0) {
          this.commercialActionsService.estimate(this.preselectCommercialAction.id, season,
            this.editFormGroup.value.general_diameter, this.editFormGroup.value.general_tire_count, axles)
            .then((response: number) => {
              if (response === 0) {
                Swal.fire({ title: 'Aucune remise disponible pour les valeurs saisies.', text: '', icon: 'warning', showCancelButton: false, confirmButtonColor: '#3085d6', cancelButtonColor: '#d33', confirmButtonText: 'ok' })
              } else {
                this.discountEstimation = response;
                this.displayDiscount = true;
              }
            })
            .catch((err) => {
              this.alertService.error(err);
            })
        } else {
          Swal.fire({
            title: 'Veuillez renseigner une valeur supérieure à zero.', text: '', icon: 'warning', showCancelButton: false, confirmButtonColor: '#3085d6', cancelButtonColor: '#d33', confirmButtonText: 'ok' });
        }
        break;
      default:
        break;
    }
  }

  get f() { return this.editFormGroup.controls; }

  get allowToCreateAgreements() {
    return (this.preselectCommercialAction && this.editFormGroup.value.customer_agency) &&
        (
          (this.remainingNationalImmediateActions > 0 && this.remainingNationalBudget > 0) ||
          (
            (this.globalRemainingCheck && ((this.globalRemainingCheck.budget - this.globalRemainingCheck.local_consumed_budget) > 0) &&
            (this.globalRemainingCheck.immediate_actions_number - this.globalRemainingCheck.local_consumed_immediate_actions_number) > 0)
          )
        );
  }

  get globalRemainingCheck() {
    let res = null;
    if (this.preselectCommercialAction.commercial_action_adherents &&
        this.preselectCommercialAction.commercial_action_adherents.length > 0) {
      const index = this.enumsService.enums.agencies.findIndex(a => a.id === this.editFormGroup.value.customer_agency);
      if (index !== -1) {
        const selectAgencyAdherent = this.enumsService.enums.agencies[index].adherent.id;
        res = this.preselectCommercialAction.commercial_action_adherents.find(ad => ad.adherent_id === selectAgencyAdherent);
      }
      return (res) ? res : null;
    }
    return null;
  }

  get remainingNationalBudget() {
    const nationalBudgetTotal: number = this.preselectCommercialAction.budget;
    const nationalBudgetUsed: number = this.preselectCommercialAction.commercial_action_national_agreements_amount;
    return nationalBudgetTotal - nationalBudgetUsed;
  }

  get remainingNationalImmediateActions() {
    const immediateActionsTotal: number = this.preselectCommercialAction.immediate_actions_number;
    const immediateActionsDone: number = this.preselectCommercialAction.commercial_action_national_agreements_count;
    return immediateActionsTotal - immediateActionsDone;
  }

  /* TODO: désactivé pour le moment, à réactiver lorsque le besoin apparaîtra */
  get commercialActionDeferredActions() {
    let rest: number = this.preselectCommercialAction.deferred_actions_number;
    this.preselectCommercialAction.commercial_action_adherents.forEach(a => {
      rest = rest - a.deferred_actions_number;
    });
    return rest;
  }

  acIsArchived(ac: CommercialAction) : Boolean {
    return new Date(ac.end_at) < new Date()
  }

  onSubmit() {
    if (this.authService.currentUserValue.adherents.length === 0 && this.authService.currentUserValue.agencies.length === 1) {
      this.editFormGroup.patchValue({customer_agency: this.authService.currentUserValue.agencies[0].id});
    }

    this.submitted = true;

    if (this.editFormGroup.invalid ||
      (this.preselectCommercialAction && this.editFormGroup.value.customer_agency === null)) {
      window.scrollTo(0, 0);
      return;
    }

    const agreement = {
      ...this.editFormGroup.value,
      user: this.authService.currentUserValue
    };

    if (this.preselectCommercialAction.action_type == 3 && this.editFormGroup.value.promo_code) {
      agreement.entered_code_agency_id = this.editFormGroup.value.customer_agency,
      agreement.entered_code_user_id = this.authService.currentUserValue.id
    }

    if (this.editMode) {
      agreement.id = this.route.snapshot.params.agreement_id;
    }

    this.agreementsService.edit(this.actionId, agreement).then((response: any) => {
      this.router.navigate([`/commercial-actions/agreements/${this.actionId}/${response.id}/consultation`]);
      this.alertService.success((this.editMode && this.editMode.active) ? 'Modification enregistrée avec succès.' : 'Accord enregistré avec succès.');
    })
    .catch((err) => {
      this.alertService.error(err);
    });
  }

  getUserFieldFromCommercialAction(slug) {
    const index = this.preselectCommercialAction.commercial_action_user_fields.findIndex(f => f.slug === slug);
    return index !== -1 ? this.preselectCommercialAction.commercial_action_user_fields[index] : null;
  }

  getGeneralFieldFromCommercialAction(slug) {
    const index = this.preselectCommercialAction.commercial_action_general_fields.findIndex(f => f.slug === slug);
    return index !== -1 ? this.preselectCommercialAction.commercial_action_general_fields[index] : null;
  }

  getAdherentById(id) {
    const index = this.enumsService.enums.adherents.findIndex(a => a.id === id);
    if (index !== -1) {
      return this.enumsService.enums.adherents[index];
    }
    return '--';
  }

  tc4(diameters) {
    if (this.actionId === 102) { // spécifique AC 102 Remise immédiate Bridgestone
      return diameters.filter(d => d >=15 && d <= 24);
    } else {
      return diameters.filter(d => d >=13 && d <= 24);
    }
  }

}
