import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import modules from '../../../../assets/modules.json';

import { AuthService } from 'src/app/services/auth.service';
import { AlertService } from 'src/app/services/alert.service';
import { CommercialActionsService } from 'src/app/services/commercial-actions.service';

import { CommercialAction } from 'src/app/models/CommercialAction';
import { User } from 'src/app/models/User';
import { EnumsService } from 'src/app/services/enums.service';
import { LoadingService } from 'src/app/services/loading.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditComponent implements OnInit {
  public modules;
  public modulesStringList;
  public user: User;
  public editFormGroup: FormGroup;
  public checkboxesUserFields: Array<any> = [];
  public checkboxesGeneralFields: Array<any> = [];
  public submitted: Boolean = false;
  public loading: Boolean = false;
  public adherentsList: Array<any> = [];
  public preselectAdherent: number;
  public budgetDispatcherList: Array<any> = [];
  public users: Array<User> = []
  public editMode: any = {
    active: false,
    data: null
  };

  public customCbUserB2c = [
    {slug : 'loyalty_card_number', label: 'Numéro de compte de fidélité'},
    {slug : 'civility', label: 'Civilité'},
    {slug : 'last_name', label: 'Nom'},
    {slug : 'first_name', label: 'Prénom'},
    {slug : 'birthday', label: 'Date de naissance'},
    {slug : 'phone', label: 'Téléphone portable'},
    {slug : 'email', label: 'Email'},
    {slug : 'invoice_number', label: "Numéro facture"}
  ];
  public customCbUserB2B = [
    {slug : 'siret', label: 'Siret'},
    {slug : 'loyalty_card_number', label: 'Numéro de compte de fidélité'},
    {slug : 'civility', label: 'Civilité'},
    {slug : 'society', label: 'Société'},
    {slug : 'address', label: 'Adresse'},
    {slug : 'last_name', label: 'Nom'},
    {slug : 'first_name', label: 'Prénom'},
    {slug : 'phone', label: 'Téléphone portable'},
    {slug : 'email', label: 'Email'},
    {slug : 'invoice_number', label: "Numéro facture"}
  ];

  public customCbGeneralB2c = [
    {slug : 'tire_count', label: 'Nombre de pneus', type: []},
    {slug : 'price_unit_ht', label: 'Prix unitaire du pneu (HT)', type: []},
    {slug : 'price_unit_ttc', label: 'Prix unitaire du pneu (TTC)', type: []},
    {slug : 'general_diameter', label: 'Diamètre de pneu', type: []},
    {slug : 'width', label: 'Largeur de pneu', type: []},
    {slug : 'tire_brand_b_g_d', label: 'Marque de pneu Bridgestone / Goodyear / Dunlop', type: []},
    {slug : 'tire_brand', label: 'Marque de pneu', type: []},
    {slug : 'vehicle_brand', label: 'Marque de véhicule', type: []},
    {slug : 'fiscal_horse', label: 'Chevaux fiscaux véhicule', type: []},
    {slug : 'season', label: 'Saison', type: [0, 1]}
  ];

  // TODO/Notes: Essieux T was renamed to 'Nombre de pneus' specifically for AC 103
  public customCbGeneralB2B = [
    {slug : 'tire_count', label: 'Nombre de pneus', type: []},
    {slug : 'price_unit_ht', label: 'Prix unitaire du pneu (HT)', type: []},
    {slug : 'price_unit_ttc', label: 'Prix unitaire du pneu (TTC)', type: []},
    {slug : 'general_diameter', label: 'Diamètre de pneu', type: []},
    {slug : 'width', label: 'Largeur de pneu', type: []},
    {slug : 'tire_brand_b_g_d', label: 'Marque de pneu Bridgestone / Goodyear / Dunlop', type: []},
    {slug : 'tire_brand', label: 'Marque de pneu', type: []},
    {slug : 'vehicle_brand', label: 'Marque de véhicule', type: []},
    {slug : 'axle_t', label: 'Nombre de pneus', type: []},
    {slug : 'axle_s', label: 'Essieux S', type: []},
    {slug : 'axle_d', label: 'Essieux D', type: []},
    {slug : 'season', label: 'Saison', type: [0, 1]}
  ];

  public newBudgetDispatch: {
    adherent: any,
    budget: number,
    deferred_actions_number: number,
    immediate_actions_number: number
  } = {
    adherent: null,
    budget: 0,
    deferred_actions_number: 0,
    immediate_actions_number: 0
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private commercialActionsService: CommercialActionsService,
    private alertService: AlertService,
    private authService: AuthService,
    private enumsService: EnumsService,
    public loadingService: LoadingService,
    private userService: UserService) {

    this.modules = modules;
    this.loading = true;

    this.enumsService.observable.subscribe({
      complete: () => {
        // Load users
        this.userService.users().then((users) => {
          this.users = users
        })

        // If there action_id this is edit mode
        if (this.route.snapshot.params.action_id) {
          this.editMode.active = true;
          this.commercialActionsService.getById(this.route.snapshot.params.action_id)
            .then((response: CommercialAction) => { // EDIT
              this.editMode.data = response;
              this.editMode.data.banner = (response.banner_url) ? [{name: 'affichage de votre fichier', link: response.banner_url}] : null;
              this.editMode.data.data_file = (response.data_file_url) ? [{name: 'affichage de votre fichier', link: response.data_file_url}] : null;
              this.loading = false;

              this.editFormGroup = this.formBuilder.group({
                name: [response.name, Validators.required],
                budget: [response.budget, [Validators.required, Validators.min(1)]],
                start_at: [response.start_at.toString().split('T')[0], Validators.required],
                end_at: [response.end_at.toString().split('T')[0], Validators.required],
                immediate_actions_number: [response.immediate_actions_number, [Validators.required, Validators.min(0)]],
                deferred_actions_number: [response.deferred_actions_number, [Validators.required, Validators.min(0)]],
                universe: [response.universe.id, null],
                rules: [response.rules, Validators.required],
                adherent_id: [response.adherent_id, null],
                commercial_action_user_fields: [response.commercial_action_user_fields, null],
                commercial_action_general_fields: [response.commercial_action_general_fields, null],
                action_type: [response.action_type, null],
                automatic_emails: [response.automatic_emails, null],
                banner: [null, null],
                data_file: [null, null],
                validity_deadline_date: [response.validity_deadline_date?.toString().split('T')[0], null],
                users: [response.users ? response.users : [], null]
              });

              this.initCodePromoSubscriber(response.action_type);

              if (response.action_type === 1) {
                this.editFormGroup.controls['data_file'].setValidators(null);
                this.editFormGroup.controls['data_file'].updateValueAndValidity();
              }

              if (response.action_type == 3) {
                this.editFormGroup.controls['validity_deadline_date'].setValidators(Validators.required);
                this.editFormGroup.controls['validity_deadline_date'].updateValueAndValidity();
              }

              this.checkboxesUserFields = response.commercial_action_user_fields;
              this.checkboxesGeneralFields = response.commercial_action_general_fields;

              // LOAD ADHERENT ASSIGNATION
              if (this.authService.currentUserValue.adherents
                && this.authService.currentUserValue.adherents.length > 0) {
                  this.adherentsList = this.authService.currentUserValue.adherents;
              }
              else {
                this.adherentsList = this.enumsService.enums.adherents;
              }

              // asign adherent_id
              this.preselectAdherent = this.adherentsList.findIndex(a => a.id === response.adherent_id);

              // PREPARE BUDGET DISPATCHER WITH DATA
              response.commercial_action_adherents.forEach(e => {
                let adherent: any = null;
                const index = this.enumsService.enums.adherents.findIndex(a => a.id === e.adherent_id);
                if (index !== -1) {
                  adherent = this.adherentsList[index];
                  this.budgetDispatcherList.push(
                    {
                      adherent,
                      budget: e.budget,
                      deferred_actions_number: e.deferred_actions_number,
                      immediate_actions_number: e.immediate_actions_number
                    }
                  );
                }
              });

            })
            .catch((err) => {
              this.alertService.error(err);
              this.loading = false;
            });

        } else {
          this.loading = false;
          this.editFormGroup = this.formBuilder.group({
            name: ['', [Validators.required, Validators.minLength(3)]],
            budget: [0, [Validators.required, Validators.min(1)]],
            start_at: [null, Validators.required],
            end_at: [null, Validators.required],
            immediate_actions_number: [0, [Validators.required, Validators.min(0)]],
            deferred_actions_number: [0, [Validators.required, Validators.min(0)]],
            universe: [0],
            rules: ['', Validators.required],
            adherent_id: [null, null],
            commercial_action_user_fields: [[], null],
            commercial_action_general_fields: [[], null],
            action_type: [0, null],
            automatic_emails: [false, null],
            banner: [null, null],
            data_file: [null, null],
            validity_deadline_date: [null, null],
            users: [[], null]
          });

          // LOAD ADHERENTS
          if (this.authService.currentUserValue.adherents
            && this.authService.currentUserValue.adherents.length > 0) {
              this.adherentsList = this.authService.currentUserValue.adherents;
              // set adherents association
              if (this.authService.currentUserValue.adherents.length === 1) {
                this.editFormGroup.patchValue({adherent_id: this.authService.currentUserValue.adherents[0].id});
                this.preselectAdherent = 0;
              }
          }
          else {
            this.adherentsList = this.enumsService.enums.adherents;
          }

          // ON action type changing
          // Add promo code validator on input form for action_type === 1
          this.editFormGroup.controls['action_type'].valueChanges.subscribe(field => {
            this.initCodePromoSubscriber(field);

            if(field === 3) {
              this.editFormGroup.controls['validity_deadline_date'].setValidators(Validators.required);
              this.editFormGroup.controls['validity_deadline_date'].updateValueAndValidity();
            } else {
              this.editFormGroup.controls['validity_deadline_date'].setValidators(null);
              this.editFormGroup.controls['validity_deadline_date'].updateValueAndValidity();
            }
          });
        }
      }
    });

  }

  ngOnInit(): void {
    if (this.authService.currentUserValue) {
      this.modules = modules;
      this.modulesStringList = Object.keys(modules);
      this.user = this.authService.currentUserValue;
    }
  }

  get f() {
    return this.editFormGroup.controls;
  }

  initCodePromoSubscriber(promo) {
    const fieldsToUpdate = [
      {name: 'budget', reset: (this.editMode.data && this.editMode.data.budget) ? this.editMode.data.budget : 0},
      {name: 'immediate_actions_number', reset: (this.editMode.data && this.editMode.data.immediate_actions_number) ? this.editMode.data.immediate_actions_number : 0},
      {name: 'deferred_actions_number', reset: (this.editMode.data && this.editMode.data.deferred_actions_number) ? this.editMode.data.deferred_actions_number : 0},
      {name: 'rules', reset: ''},
      {name: 'commercial_action_user_fields', reset: []},
      {name: 'commercial_action_general_fields', reset: []}
    ];

    if (promo  === 1 || promo === 2 || promo == 3) {
      fieldsToUpdate.forEach(field => {
        this.editFormGroup.controls[field.name].setValidators(null);
        this.editFormGroup.controls[field.name].updateValueAndValidity();
        this.editFormGroup.controls[field.name].setValue(field.reset);
      });

      this.checkboxesGeneralFields = [];
      this.checkboxesUserFields = [];

      if (promo === 1) {
        this.editFormGroup.controls['data_file'].setValidators(Validators.required);
        this.editFormGroup.controls['data_file'].updateValueAndValidity();
      }
    } else {
      fieldsToUpdate.forEach(field => {
        this.editFormGroup.controls[field.name].setValidators(Validators.required);
        this.editFormGroup.controls[field.name].updateValueAndValidity();
      });

      this.editFormGroup.controls['data_file'].setValidators(null);
      this.editFormGroup.controls['data_file'].updateValueAndValidity();
    }
  }

  public onLoadAdherents(callback) {
    if (this.authService.currentUserValue.adherents
      && this.authService.currentUserValue.adherents.length > 0) {
        this.adherentsList = this.authService.currentUserValue.adherents;
        callback();
    }
    else {
      this.adherentsList = this.enumsService.enums.adherents;
      callback();
    }
  }

  isIn(adherent) {
    const index = this.budgetDispatcherList.findIndex(e => e.adherent.id === adherent.id);
    return index !== -1;
  }

  onAddBudgetDispatch() {
    const findAdherent = this.adherentsList.find(e => e.id === parseInt(this.newBudgetDispatch.adherent));
    if (findAdherent) {

      this.budgetDispatcherList.push({
        ...this.newBudgetDispatch,
        adherent: findAdherent
      });

      this.newBudgetDispatch = {
        adherent: null,
        budget: 0,
        deferred_actions_number: 0,
        immediate_actions_number: 0
      };
    }
  }

  onChangeUserFields(e) {
    const ar = this.checkboxesUserFields;
    if (e.target.checked) {
      ar.push({slug: e.target.value, required: false});
    } else {
      const index = ar.findIndex(item => item.slug === e.target.value);
      if (index !== -1) {
        ar.splice(index, 1);
      }
    }
    this.checkboxesUserFields = ar;
    this.editFormGroup.patchValue({commercial_action_user_fields: this.checkboxesUserFields});
  }

  onChangeGeneralField(e) {
    const ar = this.checkboxesGeneralFields;
    if (e.target.checked) {
      ar.push({slug: e.target.value, required: false});
    } else {
      const index = ar.findIndex(item => item.slug === e.target.value);
      if (index !== -1) {
        ar.splice(index, 1);
      }
    }
    this.checkboxesGeneralFields = ar;
    this.editFormGroup.patchValue({commercial_action_general_fields: this.checkboxesGeneralFields});
  }

  testCb(slug, array) {
    return array.findIndex(item => item.slug === slug);
  }

  onChangeUserFieldRequired(index, require) {
    if (this.checkboxesUserFields[index]) {
      this.checkboxesUserFields[index].required = require;
    }
    this.editFormGroup.patchValue({commercial_action_user_fields: this.checkboxesUserFields});
  }

  onChangeGeneralFieldRequired(index, require) {
    if (this.checkboxesGeneralFields[index]) {
      this.checkboxesGeneralFields[index].required = require;
    }
    this.editFormGroup.patchValue({commercial_action_general_fields: this.checkboxesGeneralFields});
  }

  onSelectAdherent($event) {
    this.editFormGroup.patchValue({adherent_id: $event.id});
  }

  // BUDGET DISPATCHER
  onDeleteBudgetDispatch(item) {
    const index = this.budgetDispatcherList.findIndex(e => e === item);
    if (index !== -1) {
      this.budgetDispatcherList.splice(index, 1);
    }
  }

  onFocusOut(index, property, $event) {
    const value: string = $event.target.innerText;
    if (parseFloat(value) < 0) {
      $event.target.innerText = 0;
    } else {
      this.budgetDispatcherList[index][property] = parseFloat(value);
    }
  }

  onPromoCodeFileSelected(files: Array<File>) {
    this.editFormGroup.patchValue({data_file: files ? files[0] : null});
    if (!files && this.editMode.data) {
      this.editMode.data.data_file = null
    }
  }

  onFileSelected(files: Array<File>) {
    this.editFormGroup.patchValue({banner: files ? files[0] : ''});
    if (!files && this.editMode.data) {
      this.editMode.data.banner = null
    }
  }


  purify(field, $event) {
    this.editFormGroup.controls[field].patchValue(
      $event.target.value.replace(/\s/g, ''),
      { emitEvent: false }
    );
  }

  onSubmit() {
    this.submitted = true;
    if (this.editFormGroup.invalid) {
      window.scrollTo(0, 0);
      return;
    }

    // Adherents
    const adherents = [];
    this.budgetDispatcherList.forEach(e => {
      adherents.push(
        {
          adherent_id: e.adherent.id,
          budget: e.budget,
          deferred_actions_number: e.deferred_actions_number,
          immediate_actions_number: e.immediate_actions_number
        }
      );
    });

    const commercialAction: CommercialAction = {
      ...this.editFormGroup.value,
      commercial_action_adherents: adherents
    };

    if (this.editMode.active) {
      commercialAction.id = this.route.snapshot.params.action_id;
    }

    this.commercialActionsService.edit(commercialAction).then((response) => {
      this.alertService.success((this.editMode && this.editMode) ? 'Modification enregistrée avec succès.' : 'Action commerciale enregistrée avec succès.');
      this.router.navigate(['/commercial-actions/master-view']);
    })
    .catch((err) => {
      this.alertService.error(err);
    });
  }

}
