import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { LoadingComponent } from './components/shared/loading/loading.component';
import { SidebarComponent } from './components/shared/sidebar/sidebar.component';
import { NavComponent } from './components/shared/nav/nav.component';
import { TopbarComponent } from './components/shared/topbar/topbar.component';
import { FooterComponent } from './components/shared/footer/footer.component';
import { ModalComponent } from './components/modal/modal.component';
import { AuthService } from './services/auth.service';
import { ApiService } from './services/api.service';
import { PoliciesService } from './services/policies.service';
import { DashboardLayoutComponent } from './components/layouts/dashboard-layout/dashboard-layout.component';
import { FrontLayoutComponent } from './components/layouts/front-layout/front-layout.component';
import { DatalistComponent } from './components/datalist/datalist.component';
import { ListSearchPipe } from './helpers/listsearch.pipe';
import { TruncatePipe } from './helpers/truncate.pipe';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { IndexContentComponent } from './components/index-content/index-content.component';
import { DatatableComponent } from './components/datatable/datatable.component';
import { ShowContentComponent } from './components/show-content/show-content.component';
import { NumbersOnlyDirective } from './directives/number-only';
import { DragDropFileInputComponent } from './components/drag-drop-file-input/drag-drop-file-input.component';
import { DragDropDirective } from './directives/drag-drop.directive';
import { EnumsService } from './services/enums.service';
import { WorkersService } from './services/workers.service';
import { FileInputComponent } from './components/file-input/file-input.component';
import { DragDropModule } from '@angular/cdk/drag-drop';


import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { FilterBarComponent } from './components/filter-bar/filter-bar.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { RoundPipe } from './helpers/round.pipe';

import { NumberWithSpacesPipe } from './helpers/numberWithSpaces.pipe'

const providersList: any = [
  AuthService,
  ApiService,
  PoliciesService,
  EnumsService,
  WorkersService
];

@NgModule({
  imports: [
    CommonModule,
    AppRoutingModule,
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    SweetAlert2Module.forRoot(),
    NgxDatatableModule,
    CKEditorModule,
    DragDropModule
  ],
  exports: [
    AppRoutingModule,
    LoadingComponent,
    SidebarComponent,
    DashboardLayoutComponent,
    FrontLayoutComponent,
    NavComponent,
    TopbarComponent,
    FooterComponent,
    ModalComponent,
    ClickOutsideDirective,
    DatalistComponent,
    ListSearchPipe,
    TruncatePipe,
    RoundPipe,
    NumberWithSpacesPipe,
    IndexContentComponent,
    DatatableComponent,
    ShowContentComponent,
    NumbersOnlyDirective,
    DragDropDirective,
    DragDropFileInputComponent,
    NgxDatatableModule,
    FileInputComponent,
    FilterBarComponent,
    CKEditorModule,
    DragDropModule
  ],
  declarations: [
    ListSearchPipe,
    TruncatePipe,
    RoundPipe,
    NumberWithSpacesPipe,
    LoadingComponent,
    SidebarComponent,
    DashboardLayoutComponent,
    FrontLayoutComponent,
    NavComponent,
    TopbarComponent,
    FooterComponent,
    ModalComponent,
    DatalistComponent,
    ClickOutsideDirective,
    NumbersOnlyDirective,
    IndexContentComponent,
    DatatableComponent,
    ShowContentComponent,
    DragDropDirective,
    DragDropFileInputComponent,
    DragDropDirective,
    FileInputComponent,
    FilterBarComponent
  ],
  providers: providersList
})
export class SharedModule {}
