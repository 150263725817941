import { environment } from '../environments/environment';

export default {
  apiUrl: environment.apiUrl,
  appSignalFrontApiKey: environment.appSignalFrontApiKey,
  awsCredentials: {
    accessKeyId: environment.awsAccessKeyId,
    secretKey: environment.awsSecretAccessKey
  }
};
